import React from 'react';
import './Footer.css';

interface FooterProps {
  showLinks?: boolean;
}

const Footer: React.FC<FooterProps> = ({ showLinks = true }) => {
  return (
    <footer className="checkout-footer">
      <div className="footer-content">
        <div style={{ marginBottom: showLinks ? '1rem' : 0, color: 'rgba(255, 255, 255, 0.7)' }}>
          © Gallium Digital {new Date().getFullYear()}
        </div>
        {showLinks && (
          <nav style={{ opacity: 0.7 }}>
            <a href="https://www.moderncsv.com/terms-and-conditions/" className="footer-link">Terms and Conditions</a>
            <a href="https://www.moderncsv.com/privacy?cmplz_region_redirect=true" className="footer-link">Privacy Policy</a>
            <a href="https://www.moderncsv.com/refund-policy/" className="footer-link">Refund Policy</a>
          </nav>
        )}
      </div>
    </footer>
  );
};

export default Footer; 