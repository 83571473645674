import React, { useRef } from 'react';
import { Axios } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, FormValues } from "./declarations/form";
import { Box, Typography, Stack, List, ListItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCallback, useState, useEffect } from "react";
import { PriceCheckApiData } from "./declarations/apiRequests";
import { ControlledInput } from "./ControlledInput";
import { LicenseField } from "./LicenseField";
import { OrderTotal } from "./OrderTotal";
import { License, PriceCheck } from "./declarations/license";
import { schema } from "./declarations/yup";
import { useNavigate } from 'react-router-dom';
import { CustomDialog } from "./CustomDialog";
import { ErrorDialog, RequestErrorMessage, requestErrors } from "./declarations/errors";
import TagManager from 'react-gtm-module';
import * as Constants from "./declarations/constants";
import './Paddle.css';
import MenuBar from "./MenuBar";
import Footer from "./Footer";

declare global {
  interface Window {
    Paddle: any;
  }
}

interface FormProps {
  formType: string;
}

function Paddle({ formType }: FormProps) {
  const [priceCheck, setPriceCheck] = useState<PriceCheck>({
    price: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
    fullPrice: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
  } as PriceCheck);
  const [numLicensesField, setNumLicensesField] = useState(1);
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>();

  const navigate = useNavigate();
  const axios: Axios = require("axios");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const headerText = () => {
    switch (formType) {
      case "personal":
        return "Personal License Purchase";
      case "personal-upgrade":
        return "Personal License Upgrade";
      case "business":
        return "Business License Purchase";
      case "business-upgrade":
        return "Business License Upgrade";
    }
  };

  //Send form data to the backend and have it send the license (or licenses) to the user.
  const sendLicense = useCallback(async (formData: FormValues) => {
    console.log('sendLicense()');

    const first_name = formData.firstName.trim();
    const last_name = formData.lastName.trim();
    const email_address = formData.email.trim();
    let licenseKeys = '';

    if (formType.includes('personal')) {
      try {
        const response = await axios.post("/purchase/personal", {
          first_name: first_name,
          last_name: last_name,
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: 1,
          send_email_to_customer: 'false',
          send_email_to_me: 'false',
          attach_invoice: 'false',
          add_to_database: 'false',
          return_license_key: 'true',
        });

        // Store the license keys from the response
        licenseKeys = response.data;

        //Google tag manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            description: 'personal',
            email: formData.email,
            value: priceCheck.price,
            num_licenses: 1,
          }
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }
    else {
      try {
        const response = await axios.post("/purchase/business", {
          first_name: first_name,
          last_name: last_name,
          company_name: formData.company.trim(),
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: formData.numLicenses,
          send_email_to_customer: 'false',
          send_email_to_me: 'false',
          attach_invoice: 'false',
          add_to_database: 'false',
          return_license_key: 'true',
        });

        // Store the license keys from the response
        licenseKeys = response.data;

        //Google tag manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            description: 'business',
            email: formData.email,
            value: priceCheck.price,
            num_licenses: formData.numLicenses,
          }
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }

    //Go to the success page with license keys.
    navigate('/success', { state: { licenseString: licenseKeys } });
  }, [formType, priceCheck, setErrorDialog, navigate, axios]);

  //Paddle
  const handleCheckoutSuccess = useCallback(async (data: any) => {
    console.log("Checkout succeeded", data);
    const formValues: FormValues = {
      firstName: data.data?.custom_data?.first_name || '',
      lastName: data.data?.custom_data?.last_name || '',
      company: data.data?.custom_data?.company || '',
      email: data.data?.customer?.email || '',  // Adjust based on what’s returned
      numLicenses: data.data?.items?.at(0)?.quantity || 1, // For personal, this might be 1; for business, it can be dynamic
      whereHeard: data.data?.custom_data?.where_heard || '',
      license: '',
      addressForInvoice: '',
      vatNumberForInvoice: '',
    };

    try {
      await sendLicense(formValues);
    } catch (error) {
      console.error(error);
    }

    window.Paddle.Checkout.close();
  }, [sendLicense]);

  //Initialize on first render.
  const paddleInitialized = useRef(false);
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Paddle && !paddleInitialized.current) {
      paddleInitialized.current = true;
      window.Paddle.Environment.set(process.env.NODE_ENV === 'production' ? 'production' : 'sandbox');
      window.Paddle.Initialize({
        token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
        eventCallback: function(data: any) {
          switch(data.name) {
            case 'checkout.completed':
              handleCheckoutSuccess(data);
              break;
            default:
              break;
          }
        }
      });
    }
  }, [handleCheckoutSuccess]);

  const onSubmitButton = async (formData: FormValues) => {
    if (window.Paddle) {
      let priceId;
      const quantity = formType === 'business' ? formData.numLicenses : 1;

      if (formType === 'personal') priceId = process.env.REACT_APP_PADDLE_PRICE_PERSONAL;
      else if (quantity >= 1 && quantity <= 9) priceId = process.env.REACT_APP_PADDLE_PRICE_1_9;
      else if (quantity >= 10 && quantity <= 19) priceId = process.env.REACT_APP_PADDLE_PRICE_10_19;
      else if (quantity >= 20 && quantity <= 29) priceId = process.env.REACT_APP_PADDLE_PRICE_20_29;
      else if (quantity >= 30 && quantity <= 39) priceId = process.env.REACT_APP_PADDLE_PRICE_30_39;
      else priceId = process.env.REACT_APP_PADDLE_PRICE_40_INF;

      window.Paddle.Checkout.open({
        items: [{
          priceId: priceId,
          quantity: quantity
        }],
        customer: {
          email: formData.email,
          business: formData.company,
        },
        customData: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          company_name: formType === 'business' ? formData.company : '',
          email: formData.email,
          where_heard: formData.whereHeard,
        }
      });
    }
  };

  const handleLicenseChange = useCallback(
    (newLicenses: License[], priceCheck: PriceCheck) => {
      setPriceCheck(priceCheck);
    }, []);

  useCallback(
    (numLicenses: number) => {
      setNumLicensesField(numLicenses);
    }, []);

  const handleNumLicensesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numLicensesTemp = parseInt(event.target.value);
    const isNum = !Number.isNaN(numLicensesTemp);
    if (isNum && numLicensesTemp > 0) {
      setNumLicensesField(numLicensesTemp);
      changePriceWithQuantity(numLicensesTemp);
    }
  };

  //Update the price when the quantity is set.
  const changePriceWithQuantity = async (num_licenses: number) => {

    //Check price.
    console.log("changePriceWithQuantity: num_licenses = " + num_licenses);

    let response;
    try {
      response = await axios.post("/set_num_licenses", {
        num_licenses: num_licenses,
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    try {
      response = await axios.post("/check_price", {
        license_type: "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    const data: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: data.price,
      fullPrice: data.full_price
    });
    console.log(`Just set price: price=${data.price}, fullPrice=${data.full_price}`);
  }

  return (
    <div>
      <MenuBar />
      <Box sx={{
        py: 4,
        mb: 8,
        maxWidth: { xs: '95%', md: '90%', lg: '85%' },
        mx: 'auto',
        px: { xs: 2, sm: 3 }
      }}>

        {/* Header text section */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {headerText()}
          </Typography>

          <List sx={{ mb: 2, pl: 2 }} dense>
            {formType === "personal" && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Want the extra features from a Premium Business license? Click <a href="./business">here</a>.
                </Typography>
              </ListItem>
            )}
            {formType === "personal" && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Upgrading from a version 1 license? Click <a href="../personal-upgrade">here</a>.
                </Typography>
              </ListItem>
            )}
            {formType === "personal-upgrade" && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Want the extra features from a Premium Business license? Click <a href="./business-upgrade">here</a>.
                </Typography>
              </ListItem>
            )}
            {(formType === "personal" || formType === "personal-upgrade") && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Your name and email address will appear in the license key.
                </Typography>
              </ListItem>
            )}
            {formType === "business" && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Upgrading from version 1? Click <a href="../business-upgrade">here</a>.
                </Typography>
              </ListItem>
            )}
            {(formType === "business" || formType === "business-upgrade") && (
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Your email address and either your company or your name (if you leave company blank) will appear in the license key(s).
                </Typography>
              </ListItem>
            )}
{/*            {(
              <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                <Typography variant="body2">
                  Your license key email will be sent to the email address entered in this step.
                </Typography>
              </ListItem>
            )}*/}
          </List>
        </Box>

        <form onSubmit={handleSubmit(onSubmitButton)}>
          <Box>
            {/* Form fields section */}
            {formType === "personal-upgrade" && (
              <List sx={{ mb: 2, pl: 2 }} dense>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    Enter your version 1 license key to receive a discount on your upgrade.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    You can paste the license key, but you only need the 32 character string at the end.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.
                  </Typography>
                </ListItem>
              </List>
            )}

            {formType === "business-upgrade" && (
              <List sx={{ mb: 2, pl: 2 }} dense>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    Enter your version 1 license keys or version 2 Premium Personal license key to receive a discount on your upgrade.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    You can paste multiple license keys.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    You can paste entire license keys, but you only need the 32 character string at the end.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                  <Typography variant="body2">
                    If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.
                  </Typography>
                </ListItem>
              </List>
            )}

            <Stack spacing={2}>
              {(formType === "personal-upgrade" || formType === "business-upgrade") && (
                <LicenseField
                  name="license"
                  control={control}
                  label={"License key" + ((formType === 'business-upgrade') ? "s" : "") + " (optional)"}
                  rows={4}
                  onLicenseChange={handleLicenseChange}
                  formType={formType}
                  multiline
                />
              )}

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <ControlledInput
                    name="firstName"
                    control={control}
                    label="First Name"
                    error={errors.firstName}
                    fullWidth
                    required
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <ControlledInput
                    name="lastName"
                    control={control}
                    label="Last Name"
                    error={errors.lastName}
                    fullWidth
                    required
                  />
                </Box>
              </Box>

              {(formType === "business" || formType === "business-upgrade") && (
                <ControlledInput
                  name="company"
                  control={control}
                  label="Company (optional)"
                  error={errors.company}
                />
              )}

              <ControlledInput
                name="email"
                control={control}
                label="Email"
                error={errors.email}
                required
              />

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {(formType === "business" || formType === "business-upgrade") &&
                    //https://stackoverflow.com/a/64933799
                    <ControlledInput
                      name="numLicenses"
                      control={control}
                      type="number"
                      label="Number of licenses"
                      error={errors.numLicenses}
                      onChange={handleNumLicensesChange}
                      inputProps={{ min: "1", step: "1", pattern: "([^0-9]*)" }}
                      value={numLicensesField}
                      required
                    />
                  }
                </Box>
              </Box>

              {(!formType.includes("upgrade")) && (
                <ControlledInput
                  name="whereHeard"
                  control={control}
                  label="Where did you hear about us? (optional)"
                  error={errors.whereHeard}
                />
              )}

              <OrderTotal
                licenses={[]}
                priceCheck={priceCheck}
                formType={formType}
                isEstimate={true}
              />

              <button type="submit" className="submit-button">
                Continue to Payment
              </button>

            </Stack>
          </Box>
        </form>
      </Box>
      <Footer />

      <CustomDialog
        opened={!!errorDialog}
        title={errorDialog?.title || ''}
        body={errorDialog?.message || ''}
      />
    </div>
  );
}

export default Paddle;
