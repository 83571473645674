import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from "@mui/material";
import MenuBar from './components/MenuBar';
import Footer from './components/Footer';
import './Home.css';

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      <MenuBar />

      <main className="flex-grow container mx-auto px-4 py-8" style={{ paddingBottom: '8rem' }}>
        <div style={{ marginTop: '3rem', textAlign: 'center' }}>
          <Typography variant="h4" className="mb-6">
            Buy a Premium License
          </Typography>
        </div>

        <div className="prose max-w-none mb-8">
          <Typography className="mb-8">
            If you do not already have a Modern CSV license and would like to buy a new one,
            please click on one of the options below.
          </Typography>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="block p-6 bg-blue-100 rounded-lg">
            <div className="text-center">
              <button
                onClick={() => navigate('/paddle/personal')}
                className="link-button"
              >
                Premium Personal License
              </button>
            </div>
            <Typography className="mt-2 mb-6">
              Filtering, Date/Time Conversions, Deduplication, Joining, Splitting, and more.
            </Typography>
          </div>

          <div className="block p-6 bg-blue-100 rounded-lg">
            <div className="text-center">
              <button
                onClick={() => navigate('/paddle/business')}
                className="link-button"
              >
                Premium Business License
              </button>
            </div>
            <Typography className="mt-2 mb-6">
              Everything in Premium Personal plus Column Lookup and Analysis tools.
            </Typography>
          </div>
        </div>

        {/* Spacing before the upgrade section */}
        <div style={{ marginTop: '3rem', textAlign: 'center' }}>
          <Typography variant="h5" className="mb-6">
            Upgrade from Version 1
          </Typography>
        </div>

        <div className="prose max-w-none mb-8">
          <Typography className="mb-8">
            If you already have a Modern CSV license, you can upgrade
            either from a version 1 to version 2 or from Premium Personal version 2
            to Premium Business version 2.
          </Typography>
        </div>

        <div className="flex flex-col space-y-12">
          <div className="block p-6 bg-blue-100 rounded-lg">
            <div className="text-center">
              <button
                onClick={() => navigate('/personal-upgrade')}
                className="link-button"
              >
                Premium Personal License Upgrade
              </button>
            </div>
            <Typography className="mt-2 mb-6">
                Improved user interface, faster performance, expanded feature set, and additional themes.
            </Typography>
          </div>

          <div className="block p-6 bg-blue-100 rounded-lg">
            <div className="text-center">
              <button
                onClick={() => navigate('/business-upgrade')}
                className="link-button"
              >
                Premium Business License Upgrade
              </button>
            </div>
            <Typography className="mt-2 mb-6">
              Everything in Premium Personal plus Column Lookup and Analysis tools.
            </Typography>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;