import React from 'react';
import logo from '../img/logo light.png';
import './MenuBar.css';

const MenuBar: React.FC = () => {
  return (
    // <header className="checkout-header bg-[#0A2647]">
    <header className="checkout-header">
      <div className="header-content">
        <img src={logo} alt="Modern CSV" className="header-logo" />
      </div>
    </header>
  );
};

export default MenuBar;