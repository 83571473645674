import React from "react";
import { Box } from "@mui/material";

interface FastSpringBodyProps {
  formType: string;
}

export class FastSpring extends React.Component<FastSpringBodyProps> {
  componentDidMount() {
    // Load FastSpring script
    const script = document.createElement('script');
    script.id = 'fsc-api';
    // script.src = 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js';
    script.src = 'https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js';
    script.type = 'text/javascript';
    script.setAttribute('data-storefront', 
      this.props.formType === 'business' 
        ? process.env.REACT_APP_FASTSPRING_BUSINESS_URL || ''
        : process.env.REACT_APP_FASTSPRING_PERSONAL_URL || ''
    );
    script.setAttribute('data-access-key', process.env.REACT_APP_FASTSPRING_ACCESS_KEY || '');
    
    // Add script to document
    document.head.appendChild(script);

    // Initialize FastSpring when script loads
    script.onload = () => {
      if (window.fastspring) {
        window.fastspring.builder.reset();
        window.fastspring.builder.checkout();
      }
    };
  }

  componentWillUnmount() {
    // Cleanup: remove the script when component unmounts
    const script = document.getElementById('fsc-api');
    if (script) {
      document.head.removeChild(script);
    }
  }

  render() {
    return (
      <Box sx={{ py: 2 }}>
        <div 
          id="fsc-embedded-checkout-container"
          style={{ minHeight: '600px' }}
        ></div>
      </Box>
    );
  }
}

// Add TypeScript interface for FastSpring global object
declare global {
  interface Window {
    fastspring?: {
      builder: {
        checkout: () => void;
        reset: () => void;
      };
    };
  }
}
