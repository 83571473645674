import React from "react";
import { Container } from "@mui/material";
import Form from "./components/Form";
import Home from "./Home";
import { FastSpring } from "./components/FastSpring";
import Paddle from "./components/Paddle";
import "bootstrap/dist/css/bootstrap.css";
import { Axios } from "axios";
import { SuccessPage } from "./components/SuccessPage";
import { Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

class App extends React.Component {
  render() {

    //Google tag manager
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
    };
    TagManager.initialize(tagManagerArgs);

    //Axios configuration
    const axios: Axios = require("axios").default;
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["SameSite"] = "None";
    axios.defaults.headers.common["Secure"] = "true";
    
    //Route to the right page.
    return (
      <Routes>
        {/*<Route index element={<Container maxWidth="sm"> <Form formType="business" /> </Container>} />*/}
        <Route index element={<Container maxWidth="sm"> <Home/> </Container>} />
        {/*<Route index element={<Container maxWidth="sm"> <FastSpring formType="business" /> </Container>} />*/}
        {/*<Route index element={window.location='https://www.moderncsv.com/buy'} />*/}
        {/*<Route index element={<Container maxWidth="sm"> <Paddle formType={"business"} /> </Container>} />*/}
        <Route path="/paddle/personal" element={<Container maxWidth="sm"> <Paddle formType={"personal"} /> </Container>} />
        <Route path="/paddle/business" element={<Container maxWidth="sm"> <Paddle formType={"business"} /> </Container>} />
        <Route path="/fastspring/personal" element={<Container maxWidth="sm"> <FastSpring formType={"personal"} /> </Container>} />
        <Route path="/fastspring/business" element={<Container maxWidth="sm"> <FastSpring formType={"business"} /> </Container>} />
        <Route path="/personal" element={<Container maxWidth="lg"> <Form formType={"personal"} /> </Container>} />
        <Route path="/business" element={<Container maxWidth="lg"> <Form formType={"business"} /> </Container>} />
        <Route path="/personal-upgrade" element={<Container maxWidth="lg"> <Form formType={"personal-upgrade"} /> </Container>} />
        <Route path="/business-upgrade" element={<Container maxWidth="lg"> <Form formType={"business-upgrade"} /> </Container>} />
        <Route path="/success" element={<Container maxWidth="sm"> <SuccessPage /> </Container>} />
      </Routes>
    );
  }
}


export default App;
