import { Alert, Box } from "@mui/material";
import { Axios } from "axios";
import { useEffect, useState, useCallback, useRef } from "react";
import { ControlledInput, ControlledInputProps } from "./ControlledInput";
import { LicenseApiData, PriceCheckApiData } from "./declarations/apiRequests";
import { CustomDialog } from "./CustomDialog";
import { alerts, License, LicenseAlert, PriceCheck,
         AllLicenseStatus, computeAllLicenseStatus } from "./declarations/license";
import { ErrorDialog, RequestErrorMessage, requestErrors } from "./declarations/errors";
import * as Constants from "./declarations/constants";


export interface LicenseFieldProps extends ControlledInputProps {
  formType: string;
  onLicenseChange?: Function;
  containerSX?: Record<string, string | number | object>;
}

export function LicenseField({
  formType,
  containerSX,
  onLicenseChange: handleParentLicenseChange,
  ...inputProps
}: LicenseFieldProps) {
  const hasBeenEdited = useRef(false);
  const [inputLicense, setInputLicense] = useState<string>("");
  const [licenses, setLicenses] = useState<License[]>([]);
  const [priceCheck, setPriceCheck] = useState<PriceCheck>({
    price: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
    fullPrice: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE
  });
  const [licenseAlert, setLicenseAlert] = useState<LicenseAlert>();
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>();

  useEffect(() => {
    handleParentLicenseChange && handleParentLicenseChange(licenses, priceCheck);
    console.log("handleParentLicenseChange");
  }, [licenses, handleParentLicenseChange, priceCheck]);

  useEffect(() => {
    let all_license_status: AllLicenseStatus = computeAllLicenseStatus(formType, licenses);
    setLicenseAlert(alerts[all_license_status] as LicenseAlert);
  }, [licenses, formType]);

  const alertStyle = {
    width: "100%",
    mt: 2,
  };

  const innerBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 2,
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setInputLicense(e.target.value);

  const isError = () => licenseAlert?.severity === 'error';

  //Update the price when the Apply Licenses button is clicked.
  const checkLicense = useCallback(async () => {
    const axios: Axios = require("axios");

    //Make a list of lines from the license input field.
    const insertedLicenses = inputLicense.replace(/\n+/g, "\n").split("\n");

    //Send the lines to the backend.
    let response;
    try {
      response = await axios.post("/check_licenses", {
        licenses: insertedLicenses,
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    //Make a list of returned licenses.
    const licenseApiList: LicenseApiData[] = response?.data;
    let license_list: License[] = [];
    licenseApiList.forEach((license_api) => {
      license_list.push({
        hash: license_api.hash,
        status: license_api.status
      });
    });

    //Check prices.
    console.log("getPriceCheck");
    let license_status_list: string[] = [];
    license_list.forEach((license) => {license_status_list.push(license.status);});

    try {
      response = await axios.post("/check_price", {
        license_type: formType && formType.includes("personal") ? "personal2" : "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    const data: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: data.price,
      fullPrice: data.full_price
    });
    console.log("Just set price.");

    //Set the licenses.
    setLicenses(license_list);
  }, [inputLicense, setErrorDialog, setPriceCheck, setLicenses, formType]);

  //Clear licenses and quantity on initial render.
  useEffect(() => {
    const axios: Axios = require("axios");
    axios.post("/clear_session").catch((e: any) => {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
    });
  }, []);

  //Make sure it doesn't check licenses upon initial render.
  useEffect(() => {
    if (inputLicense.length > 0) {
      hasBeenEdited.current = true;
    }
  }, [inputLicense]);

  //Check licenses 1 second after the field is updated.
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (inputLicense.length > 0 || hasBeenEdited.current) {
        checkLicense();
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [inputLicense, checkLicense]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ...containerSX }}>
      <CustomDialog
        title={errorDialog?.title}
        body={errorDialog?.message}
        opened={!!errorDialog}
      />
      <ControlledInput
        fullWidth
        errorState={isError()}
        onChange={handleInputChange}
        value={inputLicense}
        {...inputProps}
      />
      <Box sx={innerBoxStyle}>
        {licenseAlert && (licenseAlert.message !== "empty") && (
          <Alert sx={alertStyle} severity={licenseAlert?.severity}>
            {licenseAlert?.message}
          </Alert>
        )}
      </Box>
    </Box>
  );
}
