import React from 'react';
import { Axios } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, FormValues } from "./declarations/form";
import { Box, Typography, CircularProgress, Backdrop, Stack, List, ListItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { PriceCheckApiData } from "./declarations/apiRequests";
import { ControlledInput } from "./ControlledInput";
import { LicenseField } from "./LicenseField";
import { OrderTotal } from "./OrderTotal";
import { PaymentForm } from "./PaymentForm";
import { License, PriceCheck } from "./declarations/license";
import { schema } from "./declarations/yup";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import { CustomDialog } from "./CustomDialog";
import { ErrorDialog, RequestErrorMessage, requestErrors, paymentErrors } from "./declarations/errors";
import TagManager from 'react-gtm-module';
import * as Constants from "./declarations/constants";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

interface FormProps {
  formType: string;
}

function Form({ formType }: FormProps) {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [priceCheck, setPriceCheck] = useState<PriceCheck>({
    price: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
    fullPrice: formType.includes("personal") ? Constants.PERSONAL_PRICE : Constants.BUSINESS_PRICE,
  } as PriceCheck);
  const [numLicensesField, setNumLicensesField] = useState(1);
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const axios: Axios = require("axios");

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const headerText = () => {
    switch (formType) {
      case "personal":
        return "Personal License Purchase";
      case "personal-upgrade":
        return "Personal License Upgrade";
      case "business":
        return "Business License Purchase";
      case "business-upgrade":
        return "Business License Upgrade";
    }
  };

  //Send form data to the backend and have it send the license (or licenses) to the user.
  const sendLicense = async (formData: FormValues, firstName: string = '', lastName: string = '', email: string = '') => {
    console.log('sendLicense()');

    const first_name = formData.firstName.trim() || firstName;
    const last_name = formData.lastName.trim() || lastName;
    const email_address = formData.email.trim() || email;
    let licenseKeys = '';

    if (formType.includes('personal')) {
      try {
        const response = await axios.post("/purchase/personal", {
          first_name: first_name,
          last_name: last_name,
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: 1,
          send_email_to_customer: 'true',
          send_email_to_me: 'true',
          attach_invoice: 'true',
          add_to_database: 'true',
          return_license_key: 'true',
        });

        // Store the license keys from the response
        licenseKeys = response.data;

        //Google tag manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            description: 'personal',
            email: formData.email,
            value: priceCheck.price,
            num_licenses: 1,
          }
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }
    else {
      try {
        const response = await axios.post("/purchase/business", {
          first_name: first_name,
          last_name: last_name,
          company_name: formData.company.trim(),
          email: email_address,
          where_heard: formData.whereHeard.trim(),
          address_for_invoice: formData.addressForInvoice.trim(),
          vat_number_for_invoice: formData.vatNumberForInvoice.trim(),
          num_licenses: formData.numLicenses,
          send_email_to_customer: 'true',
          send_email_to_me: 'true',
          attach_invoice: 'true',
          add_to_database: 'true',
          return_license_key: 'true',
        });

        // Store the license keys from the response
        licenseKeys = response.data;

        //Google tag manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            description: 'business',
            email: formData.email,
            value: priceCheck.price,
            num_licenses: formData.numLicenses,
          }
        });
      }
      catch (e: any) {
        setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
        return;
      }
    }

    //Go to the success page with license keys.
    navigate('/success', { state: { licenseString: licenseKeys } });
  };

  //PayPal
    //Click
  const handleClick = () => {
    setIsSubmitting(true);
  };

    //Error
  const handleError = () => {
    console.log("handleError");
    setIsSubmitting(false);
    setErrorDialog(paymentErrors["Payment Error"]);
  };

    //Cancel
  const handleCancel = () => {
    console.log("handleCancel");
    setIsSubmitting(false);
  };

  //PayPal Approval
  const handleApprove = (firstName: string, lastName: string, email: string) => {
    setIsSubmitting(true);
    sendLicense(getValues(), firstName, lastName, email);
    setIsSubmitting(false);
  };

  //Stripe Approval
  const onSubmitButton = async (data: FormValues) => {
    // setProcessing(true);

    //Free License
    if (priceCheck.price === 0)
    {
      sendLicense(data);
      return;
    }

    //Handle Stripe.
    if (!stripe || !elements) {
      return;
    }

    console.log("Stripe submitted.");
    setIsSubmitting(true);

    //Update the price (really only needed for single business license w/ no discount).
    const axios: Axios = require("axios");
    let response;
    try {
      response = await axios.post("/check_price", {
        license_type: formType.includes("personal") ? "personal2" : "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      setIsSubmitting(false);
      return;
    }

    const responseData: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: responseData.price,
      fullPrice: responseData.full_price
    });

    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: data.firstName + " " + data.lastName,
            email: data.email,
          }
        },
      },
      redirect: 'if_required'
    });


    if (payload.error) {
      console.log("Payment failed.");
      setErrorDialog(paymentErrors["Payment Error"]);
    } else {
      console.log("Payment succeeded.");
      sendLicense(data);
    }

    setIsSubmitting(false);
    console.log("PaymentForm handleSubmit(): priceCheck.price = " + priceCheck.price);
  };

  const handleLicenseChange = useCallback(
    (newLicenses: License[], priceCheck: PriceCheck) => {
      setLicenses(newLicenses);
      setPriceCheck(priceCheck);
    }, []);

  useCallback(
    (numLicenses: number) => {
      setNumLicensesField(numLicenses);
    }, []);

  const handleNumLicensesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numLicensesTemp = parseInt(event.target.value);
    const isNum = !Number.isNaN(numLicensesTemp);
    if (isNum && numLicensesTemp > 0) {
      setNumLicensesField(numLicensesTemp);
      changePriceWithQuantity(numLicensesTemp);
    }
  };

  //Update the price when the quantity is set.
  const changePriceWithQuantity = async (num_licenses: number) => {

    //Check price.
    console.log("changePriceWithQuantity: num_licenses = " + num_licenses);

    let response;
    try {
      response = await axios.post("/set_num_licenses", {
        num_licenses: num_licenses,
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    try {
      response = await axios.post("/check_price", {
        license_type: "business2",
      });
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    const data: PriceCheckApiData = response?.data;
    setPriceCheck({
      price: data.price,
      fullPrice: data.full_price
    });
    console.log(`Just set price: price=${data.price}, fullPrice=${data.full_price}`);
  }

  return (
    <div>
      <MenuBar />
      <Box sx={{
        py: 2,
        maxWidth: { xs: '95%', md: '85%', lg: '75%' },
        mx: 'auto',
        px: { xs: 2, sm: 3 }
      }}>

        {/* Header text section */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {headerText()}
          </Typography>
          {formType === "personal" && (
            <Typography>
              Upgrading from a version 1 license? Click <a href="./personal-upgrade">here</a>.<br/>
              Want the extra features from a Premium Business license? Click <a href="./business">here</a>.
            </Typography>
          )}
          {formType === "personal-upgrade" && (
            <Typography>
              Want the extra features from a Premium Business license? Click <a href="./business-upgrade">here</a>.
            </Typography>
          )}
          {(formType === "personal" || formType === "personal-upgrade") && (
            <Typography>
              Your name and email address will appear in the license key.
            </Typography>
          )}
          {formType === "business" && (
            <Typography>
              Upgrading from version 1? Click <a href="./business-upgrade">here</a>.
            </Typography>
          )}
          {(formType === "business" || formType === "business-upgrade") && (
            <Typography>
              Your email address and either your company or your name (if you leave company blank) will appear in the license key.
            </Typography>
          )}
        </Box>

        <form onSubmit={handleSubmit(onSubmitButton)}>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1.25fr 0.75fr'
            },
            gap: { xs: '20px', md: '40px' },
            mb: 12,
            '& > *:not(:last-child)': {
              borderRight: { xs: 'none', md: '1px solid rgba(0, 0, 0, 0.12)' },
              borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.12)', md: 'none' },
              paddingRight: { xs: 0, md: '40px' },
              paddingBottom: { xs: '20px', md: 0 }
            }
          }}>

            <Box>
              {/* Form fields section */}
              {formType === "personal-upgrade" && (
                <List sx={{ mb: 2, pl: 2 }} dense>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      Enter your version 1 license key to receive a discount on your upgrade.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      You can paste the license key, but you only need the 32 character string at the end.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.
                    </Typography>
                  </ListItem>
                </List>
              )}

              {formType === "business-upgrade" && (
                <List sx={{ mb: 2, pl: 2 }} dense>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      Enter your version 1 license keys or version 2 Premium Personal license key to receive a discount on your upgrade.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      You can paste multiple license keys.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      You can paste entire license keys, but you only need the 32 character string at the end.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">
                      If you think you're eligible for a discount and it says you are not, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.
                    </Typography>
                  </ListItem>
                </List>
              )}

              <Stack spacing={2}>
                {(formType === "personal-upgrade" || formType === "business-upgrade") && (
                  <LicenseField
                    name="license"
                    control={control}
                    label={"License key" + ((formType === 'business-upgrade') ? "s" : "") + " (optional)"}
                    rows={4}
                    onLicenseChange={handleLicenseChange}
                    formType={formType}
                    multiline
                  />
                )}

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <ControlledInput
                      name="firstName"
                      control={control}
                      label="First Name"
                      error={errors.firstName}
                      fullWidth
                      required
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <ControlledInput
                      name="lastName"
                      control={control}
                      label="Last Name"
                      error={errors.lastName}
                      fullWidth
                      required
                    />
                  </Box>
                </Box>

                {(formType === "business" || formType === "business-upgrade") && (
                  <ControlledInput
                    name="company"
                    control={control}
                    label="Company (optional)"
                    error={errors.company}
                  />
                )}

                <ControlledInput
                  name="email"
                  control={control}
                  label="Email"
                  error={errors.email}
                  required
                />

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    {(formType === "business" || formType === "business-upgrade") &&
                      //https://stackoverflow.com/a/64933799
                      <ControlledInput
                        name="numLicenses"
                        control={control}
                        type="number"
                        label="Number of licenses"
                        error={errors.numLicenses}
                        onChange={handleNumLicensesChange}
                        inputProps={{ min: "1", step: "1", pattern: "([^0-9]*)" }}
                        value={numLicensesField}
                        required
                      />
                    }
                  </Box>
                </Box>

                {(!formType.includes("upgrade")) && (
                  <ControlledInput
                    name="whereHeard"
                    control={control}
                    label="Where did you hear about us? (optional)"
                    error={errors.whereHeard}
                  />
                )}

                <ControlledInput
                  name="addressForInvoice"
                  control={control}
                  label="Address for Invoice (optional)"
                  error={errors.addressForInvoice}
                  rows={3}
                  multiline
                />

                <ControlledInput
                  name="vatNumberForInvoice"
                  control={control}
                  label="VAT Number for Invoice (optional)"
                  error={errors.vatNumberForInvoice}
                />

              </Stack>
            </Box>

            {/* Right column */}
            <Box>
              <OrderTotal
                licenses={licenses}
                priceCheck={priceCheck}
                onChange={setPriceCheck}
                formType={formType}
                sx={{ mb: 4 }}
              />
              <PaymentForm
                licenses={licenses}
                priceCheck={priceCheck}
                onPriceChange={setPriceCheck}
                onClick={handleClick}
                onApprove={handleApprove}
                onError={handleError}
                onCancel={handleCancel}
              />
            </Box>
          </Box>
        </form>
      </Box>
      <Footer />

      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress sx={{color: 'blue'}} />
      </Backdrop>
      <CustomDialog
        opened={!!errorDialog}
        title={errorDialog?.title || ''}
        body={errorDialog?.message || ''}
      />
    </div>
  );
}

export default Form;
